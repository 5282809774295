import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/perliia/perliia/src/templates/api.mdx.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Elle n'est pas rattachée à un utilisateur en particulier mais directement à un espace.`}</p>
    <p>{`C'est le lieu de base pour une activité, une livraison ou un véhicule.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      